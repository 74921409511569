export default {
    getUrlKey: function (queryName) {
        let reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURI(r[2]);
        } else {
            return null;
        }
    },

    isEmpty: function (obj) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
            return true;
        } else {
            return false;
        }
    },

    getUserId: function () {
        // let geekUserId = localStorage.getItem("geekUserId");
        return localStorage.getItem("geekUserId");
    },

    getToken: function () {
        return localStorage.getItem("token");
    }
}
