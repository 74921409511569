function safeJsBridge(callback) {
    if (window.WebViewJavascriptBridge) {
        callback(window.WebViewJavascriptBridge)
    } else {
        document.addEventListener(
            'WebViewJavascriptBridgeReady'
            , function() {
                callback(window.WebViewJavascriptBridge)
            },
            false
        );
    }
}

// 在合适的位置运行一次保证 bridge 存在
export function initJsBridge() {
    safeJsBridge((bridge) => {
        let u = navigator.userAgent;
        if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
            bridge.init(function(message, responseCallback) {
                let data = {
                    'Javascript Responds': '测试中文!'
                };
                if (responseCallback) {
                    responseCallback(data);
                }
            });
        }
    })
}



export default {
    callNaiveApp: (action, params, callback) => {
        safeJsBridge((bridge) => {
            let responseCallBack = (data) => {
                if (typeof(data) == 'string' && data.substr(0,1) === '{') {
                    callback(JSON.parse(data))
                } else {
                    callback(data)
                }
            };
            bridge.callHandler(action, params, responseCallBack)
        })
    },
    resigterHandle: (action, callback) => {
        safeJsBridge((bridge) => {
            bridge.registerHandler(action, function (data, responseCallback) {
                callback(data, responseCallback)
            })
        })
    }
}


