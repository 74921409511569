<template>
    <div class="home">
        <top-bar-main/>
        <div class="greyLine"></div>
        <div class="list"><span class="font-size-15">积分趋势</span></div>
        <div id="priceLine" style="width: 100%; height: 20rem;">
        </div>
        <div>
            <div class="list">
                <span class="font-size-15">市场挂单</span>
                <div class="list-item" v-for="(item ,index) in orderList" :key="index">
                    <div style="width: 100%;">
                        <span style="align-self:flex-start;color:#333">{{item.account}}</span>
                        <span style="float:right;color:#999">{{item.createDate}}</span>
                    </div>
                    <div style="width: 100%;margin:0.8rem auto">
                        <span style="color:#333">{{item.amountAvl}}</span>
                        <span style="float:right;color:#333">{{item.price}}/ITG</span>
                    </div>
                    <div style="height:0.01rem;background-color: #EAEAEA;width:100%">
                    </div>
                </div>
            </div>

        </div>
        <bottom-nav></bottom-nav>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BottomNav from "../../components/BottomNav";
    import TopBarMain from "../../components/TopBarMain";
    // eslint-disable-next-line no-unused-vars
    import * as echarts from 'echarts';

    import {
      List, Popup, Button
    } from "vant"
    export default {
        name: 'Home',
        components: {
            BottomNav,
            TopBarMain,
            [List.name]: List,
            [Popup.name]: Popup,
            [Button.name]: Button,
        },
        data() {
            return {
                opinionData: [],
                dataTimes: [],
                orderList: [],
                lineList: [],
                loading: false,
                finished: false,
                show: false,
                showContent: false,
                isAuth: true,
                invitationCode: '',
                time: null,
                page: 1,

            }
        },
        created() {


        },

    computed: {
      optionHover () {
        return {
          step: 0.5,
        }
      }
    },
        mounted() {
            // 传过来的userId
            let id = this.$route.query.userId;
            let token = this.$route.query.token;
            if(id == null){
                id = this.$utils.getUserId();
                token = this.$utils.getToken();
            }else{
                localStorage.setItem("geekUserId", id);
                localStorage.setItem("token", token)
            }
        },
        activated() {
            this.home();
        },
        deactivated() {

        },
        methods: {
          home() {
                this.$api.fishPond.home({
                    pageNum: this.page,
                    pageSize: 20,
                    userId: this.$utils.getUserId()
                }).then(res => {
                    this.orderList = res.result;
                    // this.luckyUserList = [];
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    this.finished = true;
                }).catch(() => {

                });
                this.$api.fishPond.getLines({
                    userId: this.$utils.getUserId(),
                    type: 1
                }).then(res => {
                    this.lineList = res.result;
                    if(this.lineList != undefined){
                        let timeArr = []
                        let offPotentialObj = {
                            name: '积分趋势',
                            type: 'candlestick',
                            data: [],
                            itemStyle: {
                                normal: {
                                    color: 'red', // 阳线填充颜色
                                    color0: 'lightgreen', // 阴线填充颜色
                                    lineStyle: {
                                        width: 2,
                                        color: 'orange', // 阳线边框颜色
                                        color0: 'green' // 阴线边框颜色
                                    }
                                },
                                emphasis: {
                                    color: 'black', // 阳线填充颜色
                                    color0: 'white' // 阴线填充颜色
                                }
                            }
                        }
                        for (let i = 0; i < this.lineList.length; i++) {
                            timeArr.push(this.lineList[i].showTime)
                            let oneData = []
                            oneData.push(this.lineList[i].priceOpen)
                            oneData.push(this.lineList[i].priceClose)
                            oneData.push(this.lineList[i].priceLower)
                            oneData.push(this.lineList[i].priceHigh)
                            offPotentialObj.data.push(oneData)
                        }
                        this.drawLine(timeArr, offPotentialObj);
                    }
                    // this.luckyUserList = [];
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    this.finished = true;
                }).catch(() => {

                });
            },
            drawLine(timeArr, offPotentialObj) {
                const myChart = echarts.init(document.getElementById('priceLine'), null, {
                    renderer: 'canvas',
                    useDirtyRect: false
                });
                myChart.setOption({
                    title: {
                    },
                    tooltip: {
                        show: true,
                        trigger: 'axis'
                    },
                    grid: {
                        top: '10%',
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        data: timeArr
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,
                        axisLine: {
                            show: true
                        }
                    },
                    series: offPotentialObj
                });
                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            }
        }
    }
</script>

<style scoped>
    .greyLine{
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
        height:0.5rem;
        background-color: #FAFAFA;
    }

    .list {
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1.5rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
    }

    .list-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.52);
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    .notice img {
        width: 2.5rem;
        height: 2.5rem;

    }

    input {
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        height: 100%;
        width: 100%;
        background-color: #FAFAF7;
        text-align: center;
    }
</style>
