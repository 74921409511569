<template>
    <div class="close" @click="close">
        <img src="../assets/images/close_m.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "Close",
        methods: {
            close() {
                this.$bridge.callNaiveApp("back", {}, () => {

                })
            }
        }
    }
</script>

<style scoped>
    /*.close {*/
    /*    background-color: white;*/
    /*    width: 4.5rem;*/
    /*    height: 2.2rem;*/
    /*    border-radius: 1.1rem;*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*}*/

    .close img {
        width: 7.2rem;
        height: 3.52rem;
    }
</style>
