import axios from "axios";
import {Toast} from "vant";
// import store from "../store/index";
// import router from "../router";
//utils
import utils from "../utils/utils";

const instance = axios.create();


//设置请求超时
instance.defaults.timeout = 10000;
//post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//请求拦截
instance.interceptors.request.use(
    config => {
        const token = utils.getToken();
        if (token) {
            config.headers.token = token;
            // config.headers.langCode = 0;
        }

        return config;
    },
    error => {
        return Promise.error(error);
    }
)
//响应拦截
instance.interceptors.response.use(
    res => {
        if (res.status === 200 && res.data.code == "000") {
            return Promise.resolve(res.data);
        } else if (res.status === 200 && res.data.code !== "000") {
            Toast.fail(res.data.message)
            return Promise.reject(res);
        }


        // return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
    },
    error => {
        const {response} = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);

        } else {

            if (!window.navigator.onLine) {
                // store.commit('changeNetwork', false);
            } else {
                return Promise.reject(error);
            }

        }
    }
)

const errorHandle = (status, message) => {
    switch (status) {
        case 401:
            // toLogin();
            break
        case 403:
            // toast("登录过期，请重新登录");
            // localStorage.removeItem("token");
            // // store.commit('loginSuccess', null);
            // setTimeout(() => {
            //     toLogin();
            // }, 1000);
            break
        case 404:
            toast('请求资源不存在');
            break
        default:
            console.log(message);
            break
    }

}

const toast = msg => {
    Toast({
        message: msg,
        duration: 1000,
        forbidClick: true
    })
}

// const toLogin = () => {
//     router.replace({
//         path: "/login",
//         query: {
//             redirect: router.currentRoute.fullPath
//         }
//     })
// }


export default instance;

