<template>
    <div class="topbar">
        <span class="left">趣积分</span>
        <Close/>
    </div>
</template>
<script>
    import Close from "./Close";
    export default {
        name: "TopBar",
        props: {
            hasLeftText: {type: Boolean, default: false},
            leftText: {type: String, default: ""},
            middleText: {
                type: String,
                default: "取积分"
            }
        },
        components: {
            Close
        }
    }
</script>
<style scoped>
    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        position: fixed;
        top: 0;
        left: 1.5rem;
        right: 1.5rem;
    }
    .left {
        font-size: 2rem;
    }
    .middle {
        font-size: 1.8rem;
        color: white;
    }
</style>