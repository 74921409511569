/**
 * 接口域名的管理
 * // if (process.env.NODE_ENV = "development") {
//     instance.defaults.baseURL = "http://dev.saileikeji.com:10101/";
// } else if (process.env.NODE_ENV = "") {
//     instance.defaults.baseURL = '';
// }
 */
const base = {
    // baseUrl: process.env.NODE_ENV == "development" ? 'http://dev.saileikeji.com:10074' : 'http://game-api.aimiu.net',
    // baseUrl:  'http://test.notes8.cn:10101',
    baseUrl:  'https://suiji-api.zxnotes.cn'
}

console.log(process.env.NODE_ENV)
export default base;
