import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/lib/init"
import "./assets/css/common.css"
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

//bridge
import JSbridge, {initJsBridge} from "./assets/lib/JSbridge";

initJsBridge();
Vue.prototype.$callApp = JSbridge.callNaiveApp
Vue.prototype.$bridge = JSbridge;
//api
import api from "./api";

Vue.prototype.$api = api;
//utils
import utils from "./utils/utils.js";

Vue.prototype.$utils = utils;

// console.log(utils)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

