import qs from "qs";
import axios from "./http";
import base from "./base";

const fishPond = {
    home(params) {
        return axios.post(`${base.baseUrl}/act/bsiTransferOrders`, qs.stringify(params))
    },
    getLines(params) {
        return axios.post(`${base.baseUrl}/act/getLines`, qs.stringify(params))
    },
    buyFishingRod(params) {
        return axios.post(`${base.baseUrl}/fishPond/buyFishingRod`, qs.stringify(params))
    }
}
export default fishPond;
