import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/main/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            keepAlive: true // 需要被缓存
        }

    },
    {
        path: "/middle",
        name: "Middle",
        component: () => import("../views/main/Middle"),
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: "/mine",
        name: "Mine",
        component: () => import("../views/main/Mine.vue"),
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/inviteCode',
        name: 'InviteCode',
        component: () => import(/* webpackChunkName: "about" */ '../views/sub/InviteCode.vue'),
        meta: {
            title: "账单"
        }
    },
    {
        path: '/personInfo',
        name: 'PersonInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/sub/PersonInfo.vue'),
        meta: {
            title: "个人信息"
        }
    },
    {
        path: '/fishingRecord',
        name: 'FishingRecord',
        component: () => import(/* webpackChunkName: "about" */ '../views/sub/FishingRecord.vue'),
        meta: {
            title: "转让订单"
        }
    },
    {
        path: '/shortOrder',
        name: 'ShortOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/sub/ShortOrder.vue'),
        meta: {
            title: "短拆订单"
        }
    },
    {
        path: '/fundPool',
        name: 'fundPool',
        component: () => import(/* webpackChunkName: "about" */ '../views/sub/fundPool.vue'),
        meta: {
            title: "vip股东"
        }
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
