<template>
    <div class="nav">
        <div class="nav-item" @click="switchNav(navs[0].push)">
            <img :src="this.$route.path==navs[0].push?navs[0].icon_p:navs[0].icon_n" alt="">
            <span :class="{textSelected:this.$route.path==navs[0].push}">{{navs[0].text}}</span>
        </div>
        <!--<div @click="switchNav(navs[2].push)">
            <img src="../assets/images/jingdiao.png" alt="" style="width: 5rem;height: 5rem">
        </div>-->
        <div class="nav-item" @click="switchNav(navs[1].push)">
            <img :src="this.$route.path==navs[1].push?navs[1].icon_p:navs[1].icon_n" alt="">
            <span style="font-size: 1.2rem" :class="{textSelected:this.$route.path==navs[1].push}">{{navs[1].text}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BottomNav",
        data() {
            return {
                navs: [
                    {
                        icon_n: require("@/assets/images/home.png"),
                        icon_p: require("@/assets/images/home_fill.png"),
                        text: "首页",
                        push: "/"
                    },
                    {
                        icon_n: require("@/assets/images/me.png"),
                        icon_p: require("@/assets/images/me_fill.png"),
                        text: "我的",
                        push: "/mine"
                    }/*,
                    {
                        push: "/middle"
                    }*/
                ],
                currentIndex: 0
            }
        },
        mounted() {
            // console.log(this.$route);
            // console.log(this.$router);
        },
        methods: {
            switchNav(index) {
                if (this.$route.path != index) {
                    this.$router.push(index);

                }
            }
        }
    }
</script>

<style scoped>
    .nav {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 6.5rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: white;
    }

    .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-item img {
        width: 2.5rem;
        height: 2.5rem;
    }


    .textSelected {
        color: #0073F8;
    }
</style>
