import qs from "qs";
import axios from "./http";
import base from "./base";

const user = {
    login(params) {
        return axios.post(`${base.baseUrl}/user/login`, qs.stringify(params))
    },
    myInfo(params) {
        return axios.post(`${base.baseUrl}/user/getUserInfo`, qs.stringify(params))
    },
    bonusWithdraw(params) {
        return axios.post(`${base.baseUrl}/act/bonusWithdraw`, qs.stringify(params))
    },
    addLoad(params) {
        return axios.post(`${base.baseUrl}/act/addLoad`, qs.stringify(params))
    },
    psrPrice(params) {
        return axios.post(`${base.baseUrl}/act/getMyAccount`, qs.stringify(params))
    },
    bonusAmountFlow(params) {
        return axios.post(`${base.baseUrl}/act/bonusAmountFlow`, qs.stringify(params))
    },
    changNickName(params) {
        return axios.post(`${base.baseUrl}/user/changNickName`, qs.stringify(params))
    },
    getPoolAmount(params) {
        return axios.post(`${base.baseUrl}/act/getPoolAmount`, qs.stringify(params))
    },
    setPayPassword(params) {
        return axios.post(`${base.baseUrl}/user/setPayPassword2`, qs.stringify(params))
    },
    transfer(params) {
        return axios.post(`${base.baseUrl}/act/transfer`, qs.stringify(params))
    },
    buyPoolAliPay(params) {
        return axios.post(`${base.baseUrl}/act/buyPoolAliPay`, qs.stringify(params))
    },
    fishing(params) {
        return axios.post(`${base.baseUrl}/act/myTsfOrders`, qs.stringify(params))
    },
    transferCancel(params) {
        return axios.post(`${base.baseUrl}/act/transferCancel`, qs.stringify(params))
    },
    listLoad(params) {
        return axios.post(`${base.baseUrl}/act/listLoad`, qs.stringify(params))
    },
    rePayLoad(params) {
        return axios.post(`${base.baseUrl}/act/rePayLoad`, qs.stringify(params))
    },
    imageUpload(params) {
        return axios.post(`${base.baseUrl}/user/imageUpload`, qs.stringify(params))
    }
}
export default user;
